import React from 'react';
import styled from 'styled-components';

const HomePageWrapper = styled.div`
    align-items: center;
    text-align: center;
    padding: 20px;
    font-family: "Arial Rounded MT Bold", Arial, sans-serif;
`;
const LogoImg = styled.img`
  height: 400px;
  width: auto;
  margin-bottom: 20px;
`;
const AppInfoWrapper = styled.div`
  flex: 1;
  margin: 20px;
`;

const AppTitle = styled.h1`
  font-size: 46px;
  color: #2A9DF4;
  margin-bottom: 0px;
`;

const AppDescription = styled.p`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const DownloadButton = styled.img`
  height: 40px;
`;

const HomePage = () => {
  return (
    <HomePageWrapper>
        <AppInfoWrapper>
            <LogoImg src='/img/AppIcon.png'></LogoImg>
            <AppTitle>Scavenger Hunt</AppTitle>
            <AppDescription>
            Join the ultimate adventure and experience the thrill of a Scavenger Hunt!
            </AppDescription>
        <a href='https://www.scavengerhunt.quest'><DownloadButton src='/img/Download_on_App_Store/White_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg' alt='Download_on_App_Store'/></a>
        </AppInfoWrapper>
    </HomePageWrapper>
  );
};

export default HomePage;
