import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavigationBar from './components/NavigationBar'; 
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <BrowserRouter>
        <NavigationBar/>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<ContactPage />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;