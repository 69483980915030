import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Navbar = styled.nav`
  background-color: #2280CE;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Arial Rounded MT Bold", Arial, sans-serif;
`;

const LogoImg = styled.img`
  height: 40px;
  width: auto;
  margin-right: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
`;

const ScavengerHuntTitle = styled.h1`
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 2px
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 1em;
    color: #e2e6ea;
    text-decoration: none;
    font-size: 22px;

    &.active {
      color: #FFFFFF;
      font-weight: bold;
    }
  }
`;

const LoginButton = styled.button`
  background-color: #FFFFFF;
  color: #2280CE;
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
`;

const NavigationBar = () => (
  <Navbar>
    <TitleWrapper>
      <LogoImg src="/img/AppIcon.png" alt="Logo" />
      <ScavengerHuntTitle>Scavenger Hunt</ScavengerHuntTitle>
    </TitleWrapper>
    <NavLinks>
      <NavLink exact to="/" activeClassName="active">Home</NavLink>
      <NavLink to="/privacy" activeClassName="active">Privacy</NavLink>
      <NavLink to="/contact" activeClassName="active">Contact</NavLink>
    </NavLinks>
    <LoginButton>Login</LoginButton>
  </Navbar>
);

export default NavigationBar;
