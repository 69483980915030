import React from 'react';
import './ContactPage.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCode, faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Configure the Font Awesome library
library.add(faCode, faPhone, faEnvelope, faMapMarkerAlt, faGithub, faLinkedin, faInstagram, faTwitter);

const ContactPage = () => {
  return (
    <div>
      <div className="container">
        <img className="main-image" src="/img/AppIcon.png" alt="mainImage" width="200px" />
      </div>

      <main className="container">
        <h2 id="contact" className="mt-5 text-center">Contact Us</h2>
        <div>
          <div className="main-container">
            <p>Do you have any questions? or Want to file an issue? please use the contacts below.</p>
          </div>
          <div className="container mt-5 mb-5 contact-info">
            <div className="contact-info-row">
              <div className="col-6 fs-5">
                <p>
                  <FontAwesomeIcon icon={faCode} fixedWidth />&nbsp;&nbsp;
                  <a href="https://www.jerishbovas.com">Jerish Bradly Bovas</a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} fixedWidth />&nbsp;&nbsp;
                  <a href="tel:+14372479838">+1 (437) 247-9838</a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} fixedWidth />&nbsp;&nbsp;
                  <a href="mailto:jerishbradlyb@gmail.com">jerishbradlyb@gmail.com</a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth />&nbsp;&nbsp;
                  <a href="https://www.google.com/maps/place/Etobicoke,+Toronto,+ON/@43.6723664,-79.6228499,12z/data=!3m1!4b1!4m5!3m4!1s0x882b3760d44c33c5:0x59e9c8bf2149d577!8m2!3d43.6204946!4d-79.5131983">
                    Etobicoke, Toronto, CA M9W 3G5
                  </a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faGithub} fixedWidth />&nbsp;
                  <a href="https://github.com/JerishBovas/ScavengerHunt_iOS">Scavenger Hunt</a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faLinkedin} fixedWidth />&nbsp;
                  <a href="https://www.linkedin.com/in/jerishbovas/"> jerishbovas</a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faInstagram} fixedWidth />&nbsp;
                  <a href="https://www.instagram.com/bradlyjerish/">Bradly Jerish</a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faTwitter} fixedWidth />&nbsp;
                  <a href="https://twitter.com/bovasjerish">Bovas Jerish</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-dark footer" style={{ border: '1px solid' }}>
        <p style={{ color: 'white', fontSize: '14px', textAlign: 'center', padding: '10px' }}>Copyright 2022, Jerish Bovas - All rights reserved</p>
      </footer>
    </div>
  );
};

export default ContactPage;
